.gradientborder {
  background: linear-gradient(to right, #a45eff, #2cffe4 50%);
}
.gradientbutton {
  background: linear-gradient(270.06deg, #2cffe4 -18.88%, #a45eff 161.86%);
}

.overlay::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 0;
  top: 0;
}
.overlayshow::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 0;
  top: 0;
  right: 0;
}

.gradienttext {
  -webkit-background-clip: text !important;
  -moz-background-clip: text;
  background: linear-gradient(
    to right,
    rgba(164, 94, 255, 1),
    rgba(44, 255, 228, 1)
  );
  background-clip: text !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.2);
  -moz-text-fill-color: rgba(0, 0, 0, 0.2);
}
