.gradienttext {
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  background: linear-gradient(
    to right,
    rgba(164, 94, 255, 1),
    rgba(44, 255, 228, 1)
  );
  background-clip: text !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.2);
  -moz-text-fill-color: rgba(0, 0, 0, 0.2);
}
