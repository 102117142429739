.textGrad {
  background: linear-gradient(to right, #26e288 10%, #3beffa 50%);
  background-clip: text !important;
}

.gradienttext {
  -webkit-background-clip: text !important;
  -moz-background-clip: text;
  background: linear-gradient(
    to right,
    rgba(164, 94, 255, 1),
    rgba(44, 255, 228, 1)
  );
  background-clip: text !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.2);
  -moz-text-fill-color: rgba(0, 0, 0, 0.2);
}

.gradientbutton {
  background: linear-gradient(270.06deg, #2cffe4 -18.88%, #a45eff 161.86%);
}
