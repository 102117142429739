.gradientbackground {
  background: linear-gradient(to right, #a45eff 0%, #2cffe4 100%);
}

.overlay::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  right: 0;
  z-index: 0;
  top: 0;
}
